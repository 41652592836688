import React from 'react';
import { useNavigate } from 'react-router-dom';
import cardFront from './../images/card-front.png';
import cardBack from './../images/card-back.png';
import reverseArrow from './../images/reverse-arrow.png';

function Main() {

  const navigate = useNavigate();

  function systemLogout() {
    localStorage.removeItem('isAuthorized');
    navigate('/signin', { replace: true });
  }

  return (
    <main>
      <section className="card">
        <div className="card__el">
            <div className="card__el-img card__front active">
                <img src={cardFront} className="card__img"/>
            </div>
            <div className="card__el-img card__back">
                <img src={cardBack} className="card__img"/>
            </div>
            <div className="card__reverse-btn">
                <img src={reverseArrow} className="card__reverse-btn-img"/>
                <span className="card__reverse-btn-text">Перевернуть</span>
            </div>
        </div>

        <ul className="card__links">
            <li><a href="https://disk.yandex.ru/d/KPpH20IWE7zpIw" target="_blank" rel="noopener" className="card__link">
            <span className="card__link-text">Скачать изображения</span>
            </a></li>
        </ul>

        <details className="card__description">
          <summary className="card__description-summary">Описание</summary>
          <p className="card__description-text">Изображения по ссылке выше имеют один из традиционных форматов небольших акварельных открыток: 10x15 см — 
            при желании и возможности можно распечатать</p>
        </details>

        <div className="card__quit">
          <button aria-label="выйти" type="button" onClick={systemLogout} className="card__quit-btn">Выйти</button>
        </div>
    </section>
    </main>
  );
}

export default Main;
