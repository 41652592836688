import React, { useEffect, useState } from 'react';
import Main from './Main.js';
import Login from './Login.js';
import ProtectedRouteElement from "./ProtectedRoute";
import * as auth from './../utils/auth.js';
import { api } from './../utils/api.js';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  function handleLogin() {
    setLoggedIn(true);
  }

  function animateLoginBtn() {
    const loginBtn = document.querySelector('.login__button');
    loginBtn.classList.remove('animate');
    loginBtn.classList.add('animate');
    setTimeout(function() {
      loginBtn.classList.remove('animate');
    }, 700);
  }

  async function onLogin(loginData, clearInputs) {
    auth.authorize(loginData)
      .then(async () => {
        const isAuthorized = localStorage.getItem('isAuthorized');
        if (isAuthorized) {
          await (new Promise(function (resolve) {
            animateLoginBtn();
            setTimeout(resolve, 710);
          })).then(function () {
            clearInputs();
            handleLogin();
            navigate('/', { replace: true });
          });
        }
        return;
      })
      .catch(err => {
        alert(`${err}
Попробуйте ещё раз.`);
      })
  }

  useEffect(() => {
    async function handleTokenCheck() {
      const isAuthorized = localStorage.getItem('isAuthorized');
      if (!isAuthorized) {
        return;
      }
      const isToken = await auth.checkToken();
      if (isAuthorized && isToken) {
        api.getUserInfo().then((userData) => {
          setLoggedIn(true);
          navigate("/", { replace: true });
        }).catch((err) => {
          alert(`${err}
  Что-то пошло не так. Попробуйте войти снова.`);
        })
      }
    }
    handleTokenCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Routes>
      <Route path="*" element={loggedIn ? <Navigate to="/" replace /> : <Navigate to="/signin" replace />} />
      <Route path="/" element={<ProtectedRouteElement
        element={Main}
        loggedIn={loggedIn}
      />} />
      <Route path="/signin" element={<Login onLogin={onLogin} />} />
    </Routes>
  );
}

export default App;
