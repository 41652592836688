import React from 'react';
import { useFormAndValidation } from '../utils/customHooks/useFormAndValidation.js';
import './Login.scss';
import FormInput from './FormInput.js';

function Login({ onLogin }) {

const {values, handleChange, errors, isInputValid, resetForm, isSubmitButtonActive, getInputNames} = useFormAndValidation();

const inputElements = [
    {
      id: 1,
      type: "password",
      name: "loginPassword",
      className: "login__input login__input_el_login-password",
      required: true,
      placeholder: "Пароль",
      minLength: "7"
    }
  ]

  const nameInputs = getInputNames(inputElements);
  const clearInputs = () => {
    resetForm();
  }
  const loginData = {
    password: null
  }

  // функция, формирующая данные для последующего обращения с ними на сервер
  function gatherLoginData() {
    for (const key in loginData) {
      nameInputs.forEach((el) => {
        if (el.toLowerCase().includes(key.toString())) {
          loginData[key] = values[el];
        }
      })
    }
    return loginData;
  }

  //функция submit формы (обновление пользовательских данных на сервере)
  function handleSubmit(ev) {
    ev.preventDefault();
    const loginData = gatherLoginData();
    onLogin(loginData, clearInputs);
  }

  return (
    <section className="login">
    <form onSubmit={handleSubmit} name="loginForm" className="login__form" noValidate>
          {
            inputElements.map((input) => (
              <FormInput key={input.id}
                {...input}
                value={values[input.name] || ""}
                inputElement={input}
                isInputValid={isInputValid[input.name]}
                errorMessageText={errors[input.name]}
                onChange={handleChange} />
            ))
          }
          <button type="submit" disabled={!isSubmitButtonActive} className={`login__button ${isSubmitButtonActive ? '' : 'login__button_disabled'}`}></button>
        </form>
    </section>
  );
}

export default Login;
